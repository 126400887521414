import axios from "axios";
import { Config } from "../config";

const ENDPOINT = Config.baseAPI;

const GET_ALL_BLOG = ENDPOINT + "/api/get-blog";
const SAVE_BLOG = ENDPOINT + "/api/save-blog";
const UPDATE_BLOG = ENDPOINT + "/api/update-blog";
const DELETE_BLOG = ENDPOINT + "/api/delete-blog";
const GET_BLOG_BY_BLOG_CODE = ENDPOINT + "/api/get-blog-by-blog-code/";
const GET_ALL_BLOG_BY_FILTER = ENDPOINT + "/api/get-blog-by-filter";
const COUNT_BLOG = ENDPOINT + "/api/count-blog";

export const countBlog = async () => {
    try {
        let data = await axios.get(COUNT_BLOG);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getBlogByBlogCode = async (blogcode) => {
    try {
        let data = await axios.get(GET_BLOG_BY_BLOG_CODE + `${blogcode}`);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getAllBlog = async () => {
    try {
        let data = await axios.get(GET_ALL_BLOG);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getBlogByFilter = async (blog) => {
    try {
        let data = await axios.post(GET_ALL_BLOG_BY_FILTER, blog);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const saveBlog = async (blog) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(SAVE_BLOG, blog, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};

export const updateBlog = async (blog) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(UPDATE_BLOG, blog, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};

export const deleteBlog = async (blog) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(DELETE_BLOG, blog, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};
