export const Footer = (props) => {
    return (
        <>
            <footer class="w3-padding-32 w3-center w3-margin-top" id="footer-fix">
                <span style={{ color: "white" }}>Copyright @ Mong Cai Pig</span>
                <br></br>
                <a href="/" target="_blank" class="w3-hover-text-blue" style={{ color: "white", fontSize: 20 }}>
                    Website giới thiệu kết quả đề tài đánh giá đa dạng di truyền giống lợn Móng Cái ở thành phố Móng Cái 2021-2023
                </a>
            </footer>
        </>
    );
};
