export const FooterENG = (props) => {
    return (
        <>
            <footer class="w3-padding-32 w3-center w3-margin-top" id="footer-fix">
                <span style={{ color: "white" }}>Copyright @ Mong Cai Pig</span>
                <br></br>
                <a href="/homepage" target="_blank" class="w3-hover-text-blue" style={{ color: "white", fontSize: 20 }}>
                    Website of Project on Evaluation of genetic diversity of Mong Cai pig in Mong Cai City 2021-2023
                </a>
            </footer>
        </>
    );
};
