import axios from "axios";
import { Config } from "../config";

const ENDPOINT = Config.baseAPI;

const SAVE_GALLERY = ENDPOINT + "/api/save-gallery";
const UPDATE_GALLERY = ENDPOINT + "/api/update-gallery";
const DELETE_GALLERY = ENDPOINT + "/api/delete-gallery";
const GET_ALL_GALLERY = ENDPOINT + "/api/get-gallery";

export const getAllGallery = async () => {
    try {
        let data = await axios.get(GET_ALL_GALLERY);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const saveGallery = async (gallery) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(SAVE_GALLERY, gallery, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};

export const updateGallery = async (gallery) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(UPDATE_GALLERY, gallery, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};

export const deleteGallery = async (gallery) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(DELETE_GALLERY, gallery, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};
