import axios from "axios";
import { Config } from "../config";

const ENDPOINT = Config.baseAPI;

const SAVE_FARM = ENDPOINT + "/api/save-farm";
const DELETE_FARM = ENDPOINT + "/api/delete-farm";
const UPDATE_FARM = ENDPOINT + "/api/update-farm";
const GET_ALL_FARM = ENDPOINT + "/api/get-farm";
const GET_FARM_BY_ID = ENDPOINT + "/api/get-farm-by-id/";
const GET_FARM_BY_FARM_CODE = ENDPOINT + "/api/get-farm-by-farm-code/";
const COUNT_FARM = ENDPOINT + "/api/count-farm";
const GET_ALL_FARM_BY_FILTER = ENDPOINT + "/api/get-farm-by-filter";

export const getFarmByFarmCode = async (farmcode) => {
    try {
        let data = await axios.get(GET_FARM_BY_FARM_CODE + `${farmcode}`);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getFarmById = async (id) => {
    try {
        let data = await axios.get(GET_FARM_BY_ID + `${id}`);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const countFarm = async () => {
    try {
        let data = await axios.get(COUNT_FARM);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getAllFarm = async () => {
    try {
        let data = await axios.get(GET_ALL_FARM);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const getFarmByFilter = async (farm) => {
    try {
        let data = await axios.post(GET_ALL_FARM_BY_FILTER, farm);
        return data;
    } catch (error) {
        return error.response;
    }
};

export const saveFarm = async (farm) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(SAVE_FARM, farm, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};

export const updateFarm = async (farm) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(UPDATE_FARM, farm, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};

export const deleteFarm = async (farm) => {
    try {
        let token = localStorage.getItem("token");
        if (token) {
            let data = await axios.post(DELETE_FARM, farm, {
                headers: {
                    access_token: token,
                },
            });
            return data;
        }
    } catch (error) {
        return error.response;
    }
};
